import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import FeaturedPost from '../components/FeaturedPost'

const IndexPage = ({data}) => (
  <Layout>
    <SEO title="Front End Coding" />

    <div className="article-container">

      <h1 className="text-center">Exploring <span className="nowrap">Front-End</span> Web&nbsp;Development</h1>

      <hr />
      
      {data.featuredPost?.edges?.length &&
          <FeaturedPost post={data.featuredPost.edges[0].node} />
      }

      <hr />

      <div className="article-layout two-up">
        {data.nextTwo.edges.map(({ node }) => (
          <div className="article" key={node.id}>
            <h3>{node.frontmatter.title}</h3>

            <p>{node.excerpt}</p>

            <Link
              to={node.fields.slug}
              className="button button-primary button-small"
              title={`Read: ${node.frontmatter.title}`}
            >Read More</Link>
          </div>
        ))}
      </div>

      <div className="article-layout three-up">
        {data.theRest.edges.map(({ node }) => (
          <div className="article" key={node.id}>
            <h4>{node.frontmatter.title}</h4>

            <Link
              to={node.fields.slug}
              title={`Read: ${node.frontmatter.title}`}
            >Read More</Link>
          </div>
        ))}
      </div>
    </div>
    
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    featuredPost: allMdx(sort: { fields: [frontmatter___date], order: DESC} limit: 1) {
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            featuredImage {
                childImageSharp {
                    fluid(maxWidth: 240, maxHeight: 240) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            featuredImageCaption
          }
          fields {
            slug
          }
          excerpt(pruneLength: 500)
        }
      }
    }
    nextTwo: allMdx(sort: { fields: [frontmatter___date], order: DESC}, limit: 2, skip: 1) {
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
          }
          fields {
            slug
          }
          excerpt(pruneLength: 150)
        }
      }
    }
    theRest: allMdx(sort: { fields: [frontmatter___date], order: DESC}, limit: 12, skip: 3) {
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
          }
          fields {
            slug
          }
          excerpt(pruneLength: 150)
        }
      }
    }
  }
`