import { Link } from 'gatsby';
import React from 'react';
import Img from "gatsby-image"

export default function FeaturedPost({post}) {
    let featuredImg = post.frontmatter?.featuredImage?.childImageSharp.fluid;
    let featuredImageCaption = post.frontmatter?.featuredImageCaption;

    function getCaption() {
        return {__html: featuredImageCaption};
    }

    return (
        <div className="featured-post">
            <div className="featured-post-grid align-center">
                <h2 className="h4 mb-0 is-gray">Latest Post:</h2>

                <div className="featured-post-copy readable-width">
                    <h3 className="h2 mt-0 mb-0">{post?.frontmatter?.title}</h3>
                </div>
            </div>

            <div className="featured-post-grid">
                <div>
                    {featuredImg && (
                        <figure className="featured-image">
                            <Img fluid={featuredImg} loading="auto" />
                            {featuredImageCaption && (
                                <figcaption className="featured-image-caption mt-2" dangerouslySetInnerHTML={getCaption()}></figcaption>
                            )}
                        </figure>
                    )}
                </div>

                <div className="featured-post-copy readable-width">
                    <p>{post.excerpt}</p>

                    <p>
                        <Link
                            to={post.fields.slug}
                            title={`Read: ${post?.frontmatter?.title}`}
                            className="button button-primary"
                        >
                            Read More
                        </Link>
                    </p>
                </div>
            </div>
        </div>
    );
}